import React, { useRef } from 'react';
import toast from 'react-hot-toast';
import Select from 'react-select';
import { Formik, FormikValues, Field, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createNewReport,
  SGetUsers,
  updateReport,
} from '../../../apis/app.service';

import { DatePicker, Spin } from 'antd';
import dayjs from 'dayjs';
import { IReportForm } from '../core/models';
import { useReportsPage } from '../../../store/ReportsProvider';
import AppButton from '../../sharedcomponents/Buttons/AppButton/AppButton';
import { useAuth } from '../../../store/AuthProvider';
import moment from 'moment';

const createReportSchema = Yup.object({
  name: Yup.string().required().label('Report Name'),
  type: Yup.string().required().label('Type'),
  startDate: Yup.string().nullable().required().label('Start Date'),
  endDate: Yup.string().nullable().required().label('End Date'),
});

const options = [
  { value: 'monthly', label: 'monthly' },
  { value: 'quartely', label: 'quartely' },
  { value: 'bi-annually', label: 'bi-annually' },
  { value: 'annually', label: 'annually' },
  { value: 'trend', label: 'trend' },
];

export default function CreateEditReport() {
  const { report } = useReportsPage();
  const { action, data } = report;
  const { user } = useAuth();
  const modalRef = useRef<HTMLButtonElement>(null);

  const queryClient = useQueryClient();

  const fileRef = useRef<HTMLInputElement | null>(null);

  const closeModal = () => {
    if (modalRef.current) {
      modalRef.current.click();
    }
  };

  const mutation = useMutation({
    mutationFn: createNewReport,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['reports'] });
      const { message } = data;
      toast.success(message, {
        duration: 3000,
        position: 'top-right',
      });
      closeModal();
    },
    onError(error: Error) {
      const { message } = error;
      toast.error(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
  });

  const mutationUpdate = useMutation({
    mutationFn: updateReport,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['reports'] });
      const { message } = data;
      toast.success(message, {
        duration: 3000,
        position: 'top-right',
      });
      closeModal();
    },
    onError(error: Error) {
      const { message } = error;
      toast.error(message, {
        duration: 3000,
        position: 'top-right',
      });
    },
  });

  const { data: externalUsers } = useQuery({
    queryKey: ['users', 'reports'],
    queryFn: () =>
      SGetUsers(user!._id, {
        admin_Account: user!._id,
      }),
  });

  const submit = (values: IReportForm, actions: FormikValues) => {
    const { name, type, startDate, endDate, report, recipientIds } = values;

    const month = moment(startDate, 'DD/MM/YYYY').format('MMMM');
    const year = moment(startDate, 'DD/MM/YYYY').format('YYYY');

    const formData = new FormData();

    formData.append('name', name);
    formData.append('type', type);
    formData.append('month', month);
    formData.append('year', year);
    formData.append('recipientIds', recipientIds.join());
    formData.append('startDate', startDate);
    formData.append('endDate', endDate);
    formData.append('report', report);

    if (action === 'create') {
      formData.append('created_By', user!._id);
      formData.append('admin_Account', user!._id);
      mutation.mutate(formData);
    } else if (action === 'edit') {
      mutationUpdate.mutate({ reportId: values.id!, body: formData });
    }
  };

  return (
    <div
      className="modal fade"
      id="kt_modal_create_report"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-labelledby="staticBackdropLabel"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: '900px' }}
      >
        <div className="modal-content">
          <div className="modal-header">
            {/* <Alert severity="error">This is an error alert — check it out!</Alert> */}
            <h2>
              <span className="text-capitalize">{action}</span> Report
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={modalRef}
              onClick={() => {
                if (fileRef.current) {
                  fileRef.current.value = '';
                }
              }}
            ></button>
          </div>

          <div className="modal-body">
            {/* start create form */}
            <div className="d-flex flex-column flex-xl-row flex-row-fluid">
              {/* begin form body */}
              <div className="w-100 d-flex flex-column justify-center">
                <Formik
                  validationSchema={createReportSchema}
                  initialValues={data}
                  onSubmit={submit}
                  encType="multipart/form-data"
                  enableReinitialize={true}
                >
                  {(formik) => (
                    <Form className="form">
                      {/* begin form body */}
                      <div
                        className="current"
                        data-kt-stepper-element="content"
                      >
                        <div className="w-100">
                          <div className="mb-4">
                            <label className="form-label fs-5">
                              <span>Report Name</span>
                              <i
                                className="fas fa-exclamation-circle ms-2"
                                style={{ fontSize: '1rem' }}
                                data-bs-toggle="tooltip"
                                title="Report Name"
                              ></i>
                            </label>

                            <Field
                              type="text"
                              className="form-control"
                              name="name"
                              placeholder="e.g Institution report March 2024"
                            />
                            <div className="text-danger">
                              <ErrorMessage name="name" />
                            </div>
                          </div>

                          <div className="mb-4">
                            <label className="form-label fs-5">
                              <span>Type</span>
                              <i
                                className="fas fa-exclamation-circle ms-2"
                                style={{ fontSize: '1rem' }}
                                data-bs-toggle="tooltip"
                                title="Report type"
                              ></i>
                            </label>

                            <Select
                              name="type"
                              onChange={(selectedOption: any) =>
                                formik.setFieldValue(
                                  'type',
                                  selectedOption.value
                                )
                              }
                              options={options}
                              value={{
                                value: formik.values.type,
                                label: formik.values.type,
                              }}
                            />
                            <div className="text-danger">
                              <ErrorMessage name="type" />
                            </div>
                          </div>

                          <div className="mb-4">
                            <label className="form-label fs-5">
                              <span>Recipients</span>
                              <i
                                className="fas fa-exclamation-circle ms-2"
                                style={{ fontSize: '1rem' }}
                                data-bs-toggle="tooltip"
                                title="Instititions to which report belongs"
                              ></i>
                            </label>

                            <div>
                              <Select
                                name="type"
                                onChange={(options: any) => {
                                  const recipients = options.map(
                                    (item: any) => item.value
                                  );
                                  formik.setFieldValue(
                                    'recipientIds',
                                    recipients
                                  );
                                }}
                                isMulti
                                value={externalUsers
                                  ?.filter((item: any) =>
                                    formik.values.recipientIds.includes(
                                      item.user_Id
                                    )
                                  )
                                  .map((item: any) => ({
                                    value: item.user_Id,
                                    label: item.name,
                                  }))}
                                options={externalUsers?.map((item: any) => {
                                  return {
                                    value: item.user_Id,
                                    label: item.name,
                                  };
                                })}
                              />
                            </div>
                          </div>
                          {/* end recipientIds */}

                          {/* begin period row */}
                          <div className="row mb-4">
                            {/* begin startDate */}
                            <div className="col">
                              <label className="form-label fs-5">
                                <span className="required">Start Date</span>
                                <i
                                  className="fas fa-exclamation-circle ms-2"
                                  style={{ fontSize: '1rem' }}
                                  data-bs-toggle="tooltip"
                                  title="Start date"
                                ></i>
                              </label>

                              <DatePicker
                                value={
                                  formik.values.startDate
                                    ? dayjs(
                                        formik.values.startDate,
                                        'DD/MM/YYYY'
                                      )
                                    : null
                                }
                                format={'DD/MM/YYYY'}
                                style={{ width: '100%' }}
                                placement="bottomLeft"
                                onChange={(date, dateString) =>
                                  formik.setFieldValue('startDate', dateString)
                                }
                              />

                              <div className="text-danger">
                                <ErrorMessage name="startDate" />
                              </div>
                            </div>
                            {/* end startDate */}

                            {/* begin enddate */}
                            <div className="col">
                              <label className="form-label fs-5">
                                <span className="required">End Date</span>
                                <i
                                  className="fas fa-exclamation-circle ms-2"
                                  style={{ fontSize: '1rem' }}
                                  data-bs-toggle="tooltip"
                                  title="End date"
                                ></i>
                              </label>

                              <DatePicker
                                value={
                                  formik.values.endDate
                                    ? dayjs(formik.values.endDate, 'DD/MM/YYYY')
                                    : null
                                }
                                format={'DD/MM/YYYY'}
                                style={{ width: '100%' }}
                                placement="bottomLeft"
                                onChange={(date, dateString) =>
                                  formik.setFieldValue('endDate', dateString)
                                }
                              />
                              <div className="text-danger">
                                <ErrorMessage name="endDate" />
                              </div>
                            </div>
                          </div>

                          <div className="mb-4">
                            <label className="form-label fs-5">
                              <span className="required">Upload Report</span>
                              <i
                                className="fas fa-exclamation-circle ms-2"
                                style={{ fontSize: '1rem' }}
                                data-bs-toggle="tooltip"
                                title="upload report file"
                              ></i>
                            </label>

                            <input
                              required={action === 'create'}
                              type="file"
                              className="form-control "
                              name="report"
                              accept="application/pdf"
                              onChange={(e: any) => {
                                const file: File = e.target.files[0] || '';
                                formik.setFieldValue('report', file);
                              }}
                              ref={fileRef}
                            />
                            <div className="text-danger">
                              <ErrorMessage name="report" />
                            </div>
                          </div>
                          {/* end upload file */}
                        </div>
                      </div>

                      <div className="w-100 d-flex flex-row justify-content-center">
                        <AppButton
                          btnType="success"
                          type="submit"
                          disabled={
                            mutation.isPending || mutationUpdate.isPending
                          }
                        >
                          {(mutation.isPending || mutationUpdate.isPending) && (
                            <Spin />
                          )}
                          Save
                        </AppButton>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              {/* end form body */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
