/* eslint-disable jsx-a11y/anchor-is-valid */
import MUIDataTable, {
  MUIDataTableMeta,
  MUIDataTableOptions,
} from 'mui-datatables';

import { useQuery } from '@tanstack/react-query';

import { getDevices } from '../../../apis/app.service';

import AppButton from '../../sharedcomponents/Buttons/AppButton/AppButton';
import { useDevicesPage } from '../../../store/DevicesProvider';
import { formatDate } from '../../../helpers/FormatDate';
import { IDevice, IDeviceForm } from '../core/models';
import CreateEditDevice from './CreateEditDevice';
import LoadingSpinner from '../../sharedcomponents/LoadingSpinner';
import ConfirmDeviceDelete from './ConfirmDeviceDelete';
import ConfirmDeviceActive from './ConfirmDeviceActive';

const DevicesWrapper = () => {
  const { setDevice } = useDevicesPage();

  const downloadFile = (deviceId: string) => {
    //TODO: Consider adding auth to these endpoint because its public now
    const downloadLink = `${process.env.REACT_APP_BASE_URL}/api/device/${deviceId}/feedback`;
    fetch(downloadLink, {
      method: 'GET',
    }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `${deviceId}.csv`;
        a.click();
      });
    });
  };

  const columns = [
    {
      name: 'device_id',
      label: 'Device ID',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'user_Id',
      label: 'Deployed',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
          const office = value?.find(
            (item: { role: string }) => item?.role === 'institution_head'
          );

          return <span>{office?.name}</span>;
        },
      },
    },
    {
      name: 'status',
      label: 'Network',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'total',
      label: 'Total presses',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'averageSatisfaction',
      label: 'Average %',
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: 'active',
      label: 'Active',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: boolean | undefined, tableMeta: any) => {
          const row = tableMeta.tableData[tableMeta.rowIndex];
          return (
            <div
              onClick={() => {
                setDevice((device: IDevice) => {
                  return {
                    ...device,
                    data: {
                      ...device.data,
                      device_id: row.device_id,
                      id: row._id,
                      active: row.active,
                    },
                  };
                });
              }}
            >
              <div
                className="form-check form-check-solid form-switch"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_confirm_active"
              >
                <input
                  className="form-check-input"
                  style={{
                    height: '20px',
                    width: '35px',
                  }}
                  type="checkbox"
                  checked={value}
                />
              </div>
            </div>
          );
        },
      },
    },

    {
      name: '',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        empty: false,
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
          const row = tableMeta.tableData[tableMeta.rowIndex];
          const {
            _id,
            category,
            device_id,
            gen,
            active,
            faulty,
            user_Id,
            placement,
            device_imei,
            phoneNumber,
            state,
          } = row;
          const deploymentDate = formatDate(row.deploymentDate);

          const data: IDeviceForm = {
            id: _id,
            category: category,
            gen: `${gen}`,
            device_id: device_id,
            active,
            faulty,
            user_Id: user_Id.map((item: any) => item._id),
            placement: placement || '',
            phoneNumber: phoneNumber?.slice(4) || '',
            device_imei,
            deploymentDate,
            state: state || '',
          };

          return (
            <>
              {/* begin::Menu */}
              <div
                className="d-flex flex-row align-content-center w-125px py-2"
                data-kt-menu="true"
              >
                {/* begin::Menu item */}
                <span
                  className="btn-icon-default pe-5 icon-color"
                  onClick={() => {
                    setDevice({
                      action: 'edit',
                      data,
                    });
                  }}
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_create_device"
                >
                  <i
                    className="fas fa-edit btn-icon-default"
                    data-bs-toggle="tooltip"
                    title="Edit"
                  ></i>
                </span>
                {/* end::Menu item */}

                {/* begin::Menu item */}
                <span
                  className="btn-icon-default pe-5 icon-color"
                  onClick={() => {
                    setDevice({
                      action: 'delete',
                      data,
                    });
                  }}
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_confirm_delete"
                >
                  <i
                    className="fas fa-trash btn-icon-default"
                    data-bs-toggle="tooltip"
                    title="Delete"
                  ></i>
                </span>
                {/* end::Menu item */}
                <span
                  className="btn-icon-default icon-color"
                  onClick={() =>
                    downloadFile(
                      tableMeta.tableData[tableMeta.rowIndex].device_id
                    )
                  }
                >
                  <i
                    className="fas fa-download btn-icon-default"
                    data-bs-toggle="tooltip"
                    title="Download"
                  ></i>
                </span>
                {/* end::Menu item */}
              </div>
              {/* end::Menu */}
            </>
          );
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    filterType: 'dropdown',
    viewColumns: false,
    responsive: 'standard',
    jumpToPage: true,
    downloadOptions: { filename: 'sema_reports.csv', separator: ',' },
    elevation: 1,
    customToolbar: () => {
      return (
        <AppButton
          btnType="success"
          data-bs-toggle="modal"
          width="140px"
          data-bs-target="#kt_modal_create_device"
          onClick={() =>
            setDevice({
              action: 'create',
              data: {
                category: 'field',
                gen: '4',
                active: true,
                faulty: false,
                user_Id: [],
                placement: '',
                device_imei: '',
                phoneNumber: '',
                deploymentDate: '',
                state: '',
              },
            })
          }
        >
          <i className="fa-solid fa-plus me-2"></i>
          <span>Add Device</span>
        </AppButton>
      );
    },
    customSearchRender: (searchText, handleSearch) => {
      return (
        <div className="card-title">
          <div className="d-flex align-items-center position-relative my-1">
            <input
              type="text"
              data-kt-report-table-filter="search"
              className="form-control form-control-solid ps-14 mt-3"
              style={{ width: '250px' }}
              placeholder="Search Report"
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
        </div>
      );
    },
  };

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['devices'],
    queryFn: getDevices,
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return (
      <div className="w-75 mr-auto">
        <p>Oops! Something Went Wrong! Try Again!!!</p>
        <p>{error.message}</p>
      </div>
    );
  }

  return (
    <div className="devices">
      <MUIDataTable
        title={'Devices'}
        data={data}
        columns={columns}
        options={options}
      />

      <CreateEditDevice />
      <ConfirmDeviceActive />
      <ConfirmDeviceDelete />
    </div>
  );
};

export default DevicesWrapper;
